import React from "react";
import "../../../styles/components/logoMarquee.scss";
import { LOGOS_DURATION } from "../../../constants";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { useLocation } from "@reach/router";

export const LogoMarquee = ({
  logoArray,
  logoBackground = "dark:bg-gray-2600",
  duration,
}) => {
  return (
    <div className="tag-list">
      <InfiniteLoopSlider duration={duration || LOGOS_DURATION}>
        {logoArray?.items.map((item, index) => (
          <Logo
            src={item?.brand_image?.url ? item?.logo : item?.logo}
            alt={item?.logo?.alt || ""}
            key={index}
            logoBackground={logoBackground}
            dimensions={item?.brand_image?.dimensions}
          />
        ))}
      </InfiniteLoopSlider>
      <div className="fade" />
    </div>
  );
};

const InfiniteLoopSlider = ({ children, duration, reverse = false }) => {
  return (
    <div
      className="loop-slider"
      style={{
        "--duration": `${duration}ms`,
        "--direction": reverse ? "reverse" : "normal",
      }}
    >
      <div className="inner">
        {children}
        {children}
      </div>
    </div>
  );
};

const Logo = ({
  src,
  alt,
  logoBackground,
  dimensions,
}) => (
  <div className={`tag ${logoBackground}`}>
    <img
      src={src?.url}
      alt={alt || "Logo"}
      loading="lazy"
      className={`object-scale-down min-w-[100.8px]`}
      width={100.8}
    />
    {/* <GatsbyImage
      image={getImage(src)}
      className="lg:min-w-[110px]"
      imgClassName="!object-scale-down !max-h-[84px]"
      alt={alt || "Logo"}
      loading="lazy"
      width={84}
      height={84}
    /> */}
  </div>
);

const LogosDali = ({ slice, location }) => {
  const dark = slice?.primary?.theme == "dark";
  return (
    <section className={dark ? "dark_slice_background" : ""}>
      <section className="relative px-4 mx-auto sm:px-12 lg:px-5 max-w-7xl">
        <article
          className={` lg:mx-11 ${location?.pathname !== "/pricing/" ? "pt-10 pb-0 " : ""}`}
        >
          <div className="img_grayscale_opacity">
            <p
              className={`text-center uppercase ${dark ? "text-white" : "text-gray-1300"} sm:text-sm text-2xs tracking-[0.07em]`}
            >
              {slice?.primary?.caption_title?.text}
            </p>
          </div>

          <LogoMarquee logoArray={slice} />
        </article>
      </section>
    </section>
  );
};

export default LogosDali;
